.teams-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.teams-header30 {
  gap: var(--dl-space-space-twounits);
  height: 1000px;
}

.teams-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.teams-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.teams-container2 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(215, 196, 159, 0.5);
}

.teams-layout300 {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
}

.teams-text {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  font-size: 80px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 700;
}

.teams-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.teams-content {
  gap: 48px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.teams-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
}

.teams-feature2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-maincolors-secondary);
}

.teams-feature2-image {
  width: 100%;
  padding: var(--dl-space-space-unit);
}

.teams-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.teams-feature21 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-maincolors-secondary);
}

.teams-feature2-image1 {
  width: 100%;
  padding: var(--dl-space-space-unit);
}

.teams-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.teams-feature3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-maincolors-secondary);
}

.teams-feature3-image {
  width: 100%;
  padding: var(--dl-space-space-unit);
}

.teams-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.teams-text3 {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  font-size: 80px;
  align-self: center;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  font-weight: 700;
}

.teams-navlink {
  display: contents;
}

.teams-image1 {
  top: -15px;
  left: 2px;
  width: 150px;
  height: auto;
  position: absolute;
  margin-top: auto;
  text-decoration: none;
}

@media(max-width: 991px) {
  .teams-header30 {
    height: 800px;
  }

  .teams-text {
    text-align: center;
  }

  .teams-text3 {
    text-align: center;
  }
}

@media(max-width: 767px) {
  .teams-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .teams-row {
    align-items: center;
    flex-direction: row;
  }

  .teams-feature2 {
    width: auto;
  }

  .teams-feature21 {
    width: auto;
  }

  .teams-feature3 {
    width: auto;
  }
}

@media(max-width: 479px) {
  .teams-header30 {
    height: 1800px;
  }

  .teams-row {
    flex-direction: column;
  }

  .teams-image1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
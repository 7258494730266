.navbar-container {
  width: 100%;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar-navbar-interactive {
  width: 100%;
  position: relative;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-maincolors-secondary);
}

.navbar-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.navbar-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 100px;
  flex-direction: row;
  justify-content: flex-start;
}

.navbar-link1 {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-decoration: none;
}

.navbar-link2 {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-decoration: none;
}

.navbar-link3 {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-decoration: none;
}

.navbar-link4 {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-decoration: none;
}

.navbar-link5 {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-decoration: none;
}

.navbar-buttons {
  gap: var(--dl-space-space-twounits);
  display: none;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}

.navbar-action1 {
  display: flex;
  flex-direction: row;
}

.navbar-action2 {
  display: flex;
  flex-direction: row;
}

.navbar-burger-menu {
  display: none;
}

.navbar-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar-navlink {
  display: contents;
}

.navbar-logo {
  height: 3rem;
  text-decoration: none;
}

.navbar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-link11 {
  text-decoration: none;
}

.navbar-link21 {
  text-decoration: none;
}

.navbar-link31 {
  text-decoration: none;
}

.navbar-link41 {
  text-decoration: none;
}

.navbar-link51 {
  text-decoration: none;
}

.navbar-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: none;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}




@media(max-width: 767px) {
  .navbar-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .navbar-desktop-menu {
    display: none;
  }

  .navbar-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media(max-width: 479px) {
  .navbar-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }

  .navbar-burger-menu {
    justify-content: flex-end;
  }

  .navbar-mobile-menu {
    padding: var(--dl-space-space-unit);
    background-color: var(--dl-color-maincolors-secondary);
  }

  .navbar-logo {
    width: 100px;
    height: auto;
  }
}
.footer1-footer1 {
  width: 100%;
  height: auto;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: auto;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(0deg, rgb(215, 196, 159) 0.00%,rgb(52, 49, 72) 100.00%);
}
.footer1-max-width {
  width: 100%;
  display: flex;
  z-index: -100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.footer1-content {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius4);
}
.footer1-newsletter {
  gap: 24px;
  width: 500px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer1-image1 {
  width: var(--dl-size-size-medium);
  height: auto;
}
.footer1-actions {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-content2 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  height: auto;
  font-size: 12px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.footer1-links {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.footer1-column3 {
  gap: var(--dl-space-space-unit);
  width: auto;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
}
.footer1-social-links {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link {
  display: contents;
}
.footer1-link01 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-left: 8px 0;
  text-decoration: none;
}
.footer1-link02 {
  display: contents;
}
.footer1-link03 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-link04 {
  display: contents;
}
.footer1-link05 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-link06 {
  display: contents;
}
.footer1-link07 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-column31 {
  gap: var(--dl-space-space-unit);
  width: auto;
  overflow: hidden;
  flex-grow: 1;
  max-width: 300px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: row;
}
.footer1-social-links1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.footer1-link08 {
  display: contents;
}
.footer1-link09 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-left: 8px 0;
  text-decoration: none;
}
.footer1-icon08 {
  width: 24px;
  height: 24px;
}
.footer1-link10 {
  display: contents;
}
.footer1-link11 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-image {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.footer1-link12 {
  display: contents;
}
.footer1-link13 {
  gap: 12px;
  display: flex;
  padding: 8px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
.footer1-image1 {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.footer1-credits {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.footer1-row {
  gap: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}
.footer1-link14 {
  text-decoration: underline;
}
.footer1-root-class-name {
  margin-top: auto;
}

.footer1-root-class-name2 {
  margin-top: auto;
}
.footer1-root-class-name3 {
  margin-top: auto;
}
.footer1-root-class-name4 {
  margin-top: auto;
}
@media(max-width: 991px) {
  .footer1-newsletter {
    width: 300px;
  }
}
@media(max-width: 767px) {
  .footer1-content {
    flex-direction: column;
  }
  .footer1-newsletter {
    width: 100%;
  }
  .footer1-links {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
  .footer1-column3 {
    align-items: center;
  }
  .footer1-social-links {
    align-self: center;
  }
  .footer1-column31 {
    align-items: center;
  }
  .footer1-social-links1 {
    align-self: center;
  }
  .footer1-row {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .footer1-actions {
    width: 100%;
  }
  .footer1-links {
    flex-direction: column;
  }
  .footer1-column3 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-social-links {
    align-items: center;
    justify-content: center;
  }
  .footer1-column31 {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .footer1-social-links1 {
    align-items: center;
    justify-content: center;
  }
  .footer1-credits {
    gap: 0;
  }
  .footer1-row {
    align-items: center;
    justify-content: center;
  }
}

.schedule-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.schedule-header30 {
  gap: var(--dl-space-space-twounits);
  height: 700px;
}
.schedule-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.schedule-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.schedule-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}
.schedule-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: 150px;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: column;
}
.schedule-text {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-align: center;
}
.schedule-text1 {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.schedule-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.schedule-button {
  display: none;
  border-width: 0px;
  background-color: var(--dl-color-maincolors-primary);
}
.schedule-text2 {
  text-align: center;
}
.schedule-button1 {
  text-decoration: none;
}
.schedule-text3 {
  text-align: center;
}
.schedule-container2 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(215, 196, 159, 0.5);
}
.schedule-navlink {
  display: contents;
}
.schedule-image1 {
  top: -15px;
  left: 2px;
  width: 150px;
  height: auto;
  position: absolute;
  margin-top: auto;
  text-decoration: none;
}
@media(max-width: 991px) {
  .schedule-text {
    text-align: center;
  }
  .schedule-text1 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .schedule-content {
    margin-top: 100px;
  }
  .schedule-actions {
    width: 100%;
    flex-direction: column;
  }
  .schedule-button {
    width: 100%;
  }
  .schedule-button1 {
    width: 100%;
  }
  .schedule-image1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}

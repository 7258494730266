.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-maincolors-primary);
}

.home-header30 {
  gap: var(--dl-space-space-twounits);
  height: 700px;
}

.home-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.home-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}

.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-self: stretch;
  margin-top: 150px;
  align-items: center;
  margin-bottom: 0px;
  flex-direction: column;
}

.home-text {
  fill: var(--dl-color-maincolors-primary);
  color: var(--dl-color-maincolors-primary);
  text-align: center;
}

.home-text1 {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}

.home-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}

.home-button {
  display: none;
  border-width: 0px;
  background-color: var(--dl-color-maincolors-primary);
}

.home-text2 {
  text-align: center;
}

.home-button1 {
  text-decoration: none;
}

.home-text3 {
  text-align: center;
}

.home-container2 {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(215, 196, 159, 0.5);
}

.home-layout251 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.home-max-width1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.home-section-title {
  align-self: center;
}

.home-column {
  gap: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-shrink: 0;
}

.home-text4 {
  fill: var(--dl-color-maincolors-secondary);
  color: rgba(215, 196, 159, 0.68);
}

.home-content1 {
  gap: 48px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.home-row {
  align-items: center;
  justify-content: center;
}

.home-feature1 {
  flex: 1;
  transition: 0.3s;
  text-decoration: none;
}

.home-feature1:hover {
  background-color: rgba(8, 8, 8, 0.2);
}

.home-image1 {
  width: 350px;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.home-feature2 {
  flex: 1;
  transition: 0.3s;
  text-decoration: none;
}

.home-feature2:hover {
  background-color: rgba(8, 8, 8, 0.2);
}

.home-image2 {
  width: 350px;
  height: 234px;
  object-fit: cover;
  transition: 0.3s;
}

.home-feature3 {
  flex: 1;
  transition: 0.3s;
  text-decoration: none;
}

.home-feature3:hover {
  background-color: rgba(8, 8, 8, 0.2);
}

.home-image3 {
  width: 200px;
  object-fit: cover;
  transition: 0.3s;
}

.home-row1 {
  align-items: center;
  justify-content: center;
}

.home-feature11 {
  flex: 1;
  transition: 0.3s;
  text-decoration: none;
}

.home-feature11:hover {
  background-color: rgba(8, 8, 8, 0.2);
}

.home-image4 {
  width: 350px;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.home-feature21 {
  flex: 1;
  transition: 0.3s;
  text-decoration: none;
}

.home-feature21:hover {
  background-color: rgba(8, 8, 8, 0.2);
}

.home-image5 {
  width: 350px;
  height: auto;
  object-fit: cover;
  transition: 0.3s;
}

.home-feature31 {
  flex: 1;
  transition: 0.3s;
  text-decoration: none;
}

.home-feature31:hover {
  background-color: rgba(8, 8, 8, 0.2);
}

.home-image6 {
  width: 350px;
  object-fit: cover;
  transition: 0.3s;
}

.home-navlink {
  display: contents;
}

.home-image1 {
  top: -15px;
  left: 2px;
  width: 150px;
  height: auto;
  position: absolute;
  margin-top: auto;
  text-decoration: none;
}

@media(max-width: 991px) {
  .home-text {
    text-align: center;
  }

  .home-text1 {
    text-align: center;
  }

  .home-section-title {
    align-items: flex-start;
    flex-direction: column;
  }

  .home-row {
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .home-image1 {
    width: 150px;
  }

  .home-image2 {
    width: 300px;
  }

  .home-image3 {
    width: 200px;
  }

  .home-row1 {
    align-items: center;
    justify-content: center;
  }

  .home-image4 {
    width: 300px;
  }

  .home-image5 {
    width: 200px;
  }

  .home-image6 {
    width: 300px;
  }
}

@media(max-width: 767px) {
  .home-column {
    width: 100%;
  }

  .home-text4 {
    text-align: center;
  }

  .home-row {
    align-items: center;
    flex-direction: column;
  }

  .home-row1 {
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width: 479px) {
  .home-content {
    margin-top: 100px;
  }

  .home-actions {
    width: 100%;
    flex-direction: column;
  }

  .home-button {
    width: 100%;
  }

  .home-button1 {
    width: 100%;
  }

  .home-row {
    align-self: center;
    align-items: center;
  }

  .home-row1 {
    align-items: center;
  }

  .home-image1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}